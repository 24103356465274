import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { usePathname } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { AppState } from "@app/store/store";
import {
    setBody,
    setBrand,
    setDrive,
    setEngineType,
    setEngineVolumeFrom,
    setEngineVolumeTo,
    setGeneration,
    setHorsePowerFrom,
    setHorsePowerTo,
    setMileageFrom,
    setMileageTo,
    setModel,
    setOwnersQntFrom,
    setOwnersQntTo,
    setPriceFrom,
    setPriceTo,
    setSeller,
    setSource,
    setTransmission,
    setWheel,
    setYearFrom,
    setYearTo
} from "@app/store/filtersSlice";
import {
    useGetBodyTypesOptionsQuery,
    useGetBrandsOptionsQuery,
    useGetDriveTypesOptionsQuery,
    useGetEngineTypesOptionsQuery,
    useGetGenerationsOptionsQuery,
    useGetModelsOptionsQuery,
    useGetSellerTypesOptionsQuery,
    useGetTransmissionOptionsQuery,
    useGetDisplacementsOptionsQuery,
    useGetWheelsOptionsQuery
} from "@app/store/filtersOptionsApi";
import { Generation } from "@app/store/types";
import { IconWithLabel } from "@src/components/UI/Inputs/IconWithLabel";
import { createLabel, printNumber } from "@src/utils/utils";
import { Option, OptionsGroup } from "@components/UI/Inputs/SelectInput/types";
import SelectInput from "@components/UI/Inputs/SelectInput/SelectInput";
import { OWNERS_QNT, PLATFORMS } from "@components/Features/Catalog/catalogUtils";
import LocationButton from "@components/UI/Buttons/LocationButton/LocationButton";
import LocationFilter from "@components/Features/MainPage/LocationFilter/LocationFilter";
import ExpanderIcon from "@components/Svg/ExpanderIcon";
import RangeInput from "@components/UI/Inputs/RangeInput/RangeInput";
import FilterDoubleInput from "@components/UI/Inputs/FilterDoubleInput/FilterDoubleInput";
import TextInput from "@components/UI/Inputs/TextInput/TextInput";
import FilterLineInput from "@components/UI/Inputs/FilterLineInput/FilterLineInput";
import FilterButton from "@components/UI/Buttons/FilterButton/FilterButton";
import LocationIcon from "@components/Svg/LocationIcon";
import CancelIcon from "@components/Svg/CancelIcon";
import {
    CustomLoadingMessage,
    CustomNoOptionsMessage,
    Group
} from "@components/UI/Inputs/SelectInput/SelectInputCustoms";
import LocationConfirm from "@components/Features/MainPage/LocationConfirm/LocationConfirm";
import { CURRENT_YEAR, START_YEAR_PRODUCTION } from "@shared/config/Date";
import { numberToRoman } from "@shared/lib/utils";
import { BREAKPOINT_EXPANDED, FILTER_LABELS, YEARS_PRODUCTION } from "./constants";
import { CustomSelectComponents, MainFilterProps, SelectOption, SelectValue } from "./types";
import classes from "./MainFilter.module.scss";
import classNames from "classnames";
import { ColorInputAccent } from "@app/styles/color-palette";
import { CompareIcon } from "@shared/ui/Icon/ui/Common/CompareIcon";
import { selectCompareCount } from "@entities/compare";
import { RouterConfig } from "@shared/lib/routerConfig";

const MainFilter = ({
    isMainPage = true,
    onFilterSubmit,
    resetFilters,
    previewQnt,
    isPreviewQntFetching,
    updatePreviewQntIsLoading,
    disableMapListMode
}: MainFilterProps) => {
    const {
        brand: brandValue,
        model: modelValue,
        yearFrom: yearFromValue,
        yearTo: yearToValue,
        engineVolumeFrom: engineVolumeFromValue,
        engineVolumeTo: engineVolumeToValue,
        body: bodyValue,
        transmission: transmissionValue,
        engineType: engineTypeValue,
        drive: driveValue,
        wheel: wheelValue,
        priceFrom: priceFromValue,
        priceTo: priceToValue,
        mileageFrom: mileageFromValue,
        mileageTo: mileageToValue,
        horsePowerFrom: horsePowerFromValue,
        horsePowerTo: horsePowerToValue,
        generation: generationValue,
        ownersQntFrom: ownerQntFrom,
        ownersQntTo,
        sourceId,
        sellerType
    } = useSelector((state: AppState) => state.filters);
    const [btnLabel, setBtnLabel] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLocationFilterShown, setIsLocationFilterShown] = useState(false);
    const [isLocationConfirmShown, setIsLocationConfirmShown] = useState(false);
    const targetExpandedRef = useRef<HTMLDivElement | null>(null);

    const compareCount = useSelector(selectCompareCount);
    const showBadgeCount = compareCount > 0;

    const { data: brandsOptions, isFetching: brandsOptionsIsFetching } = useGetBrandsOptionsQuery(
        {}
    );

    const brandsOptionsFormatted: OptionsGroup[] = brandsOptions
        ? [
              {
                  label: "Все",
                  options: brandsOptions.map((brand: SelectOption) => ({
                      value: brand.id,
                      label: brand.name,
                      alias: brand.alias
                  }))
              }
          ]
        : [];

    const { data: modelOptions, isFetching: modelOptionsIsFetching } = useGetModelsOptionsQuery(
        { brandUuid: brandValue?.id },
        {
            skip: !brandValue?.id
        }
    );

    const modelFormattedOptions: OptionsGroup[] = React.useMemo(() => {
        if (modelOptionsIsFetching) {
            return [];
        } else if (modelOptions && modelOptions.length > 0) {
            return [
                {
                    label: "Все",
                    options: modelOptions.map((model: SelectOption) => ({
                        value: model.id,
                        label: model.name,
                        alias: model.alias
                    }))
                }
            ];
        }
        return [];
    }, [modelOptions, modelOptionsIsFetching]);

    const { data: generationsOptions, isFetching: isFetchingGenerations } =
        useGetGenerationsOptionsQuery(
            { model_uuid: modelValue?.id },
            {
                skip: !modelValue?.id
            }
        );

    const generationFormattedOptions: OptionsGroup[] = React.useMemo(() => {
        if (isFetchingGenerations) {
            return [];
        } else if (generationsOptions && generationsOptions.length > 0) {
            return generationsOptions.map((gen: Generation) => {
                const { id, name, generation, restyleNumber, startedAt, endedAt } = gen;

                const validStartedAt =
                    startedAt !== undefined && startedAt >= START_YEAR_PRODUCTION
                        ? startedAt
                        : undefined;
                const validEndedAt =
                    endedAt !== undefined && endedAt >= START_YEAR_PRODUCTION
                        ? endedAt
                        : CURRENT_YEAR;

                const period = validStartedAt ? `${validStartedAt} - ${validEndedAt}` : "";
                const generationNum = generation ? numberToRoman(generation) : "";
                const restyle = (restyleNumber || 0) > 0 ? `${restyleNumber} Рестайлинг` : "";

                const parts = [
                    period,
                    generationNum ? `${generationNum}` : "",
                    name ? `${name}` : "",
                    restyle
                ]
                    .filter((part) => part)
                    .join(" ");

                return {
                    value: id,
                    label: parts.trim(),
                    generation,
                    restyleNumber
                };
            });
        }
        return [];
    }, [generationsOptions, isFetchingGenerations]);

    const { data: bodyOptions, isFetching: bodyOptionsIsFetching } = useGetBodyTypesOptionsQuery(
        {
            brandUuid: brandValue?.id,
            modelUuid: modelValue?.id,
            generationUuid: generationValue?.id
        },
        {
            skip: !isExpanded
        }
    );

    const { data: transmissionOptions, isFetching: transmissionOptionsIsFetch } =
        useGetTransmissionOptionsQuery(
            {
                brandUuid: brandValue?.id,
                modelUuid: modelValue?.id,
                generationUuid: generationValue?.id
            },
            {
                skip: !isExpanded
            }
        );

    const { data: engineTypesOptions, isFetching: engineTypesIsFetching } =
        useGetEngineTypesOptionsQuery(
            {
                brandUuid: brandValue?.id,
                modelUuid: modelValue?.id,
                generationUuid: generationValue?.id
            },
            {
                skip: !isExpanded
            }
        );

    const { data: driveTypesOptions, isFetching: driveTypesIsFetching } =
        useGetDriveTypesOptionsQuery(
            {
                brandUuid: brandValue?.id,
                modelUuid: modelValue?.id,
                generationUuid: generationValue?.id
            },
            {
                skip: !isExpanded
            }
        );

    const { data: displacementsOptions, isFetching: displacementsIsFetching } =
        useGetDisplacementsOptionsQuery(
            {
                brandUuid: brandValue?.id,
                modelUuid: modelValue?.id,
                generationUuid: generationValue?.id
            },
            {
                skip: !isExpanded
            }
        );

    const { data: wheelsOptions, isFetching: wheelsIsFetching } = useGetWheelsOptionsQuery(
        {
            brandUuid: brandValue?.id,
            modelUuid: modelValue?.id,
            generationUuid: generationValue?.id
        },
        {
            skip: !isExpanded
        }
    );

    const { data: sellerTypesOptions, isFetching: sellerTypesIsFetching } =
        useGetSellerTypesOptionsQuery(
            {},
            {
                skip: !isExpanded
            }
        );

    const dispatch = useDispatch();
    const router = useRouter();
    const pathname = usePathname();
    const targetPath = pathname === "/" ? "/catalog" : pathname;

    const selectInputFonts = {
        fontWeight: 500,
        fontSize: "13px"
    };

    const customSelectComponents: CustomSelectComponents = {
        Group: (props) => <Group {...props} className={classes.groupSelect} />,
        NoOptionsMessage: (props) => (
            <CustomNoOptionsMessage {...props} className={classes.customSelect} />
        ),
        LoadingMessage: () => <CustomLoadingMessage className={classes.customSelect} />
    };

    const createSelectValue = (
        value: string | number | undefined,
        label: string = "",
        alias?: string
    ): SelectValue | undefined => {
        if (value === undefined || value === null || value === "") return undefined;
        return { value, label: label || value.toString(), alias };
    };

    const createGenerationSelectValue = (
        generation?: Generation | null
    ): SelectValue | undefined => {
        if (!generation || !generation.id) return undefined;

        const generationNum = generation.generation ? numberToRoman(generation.generation) : "";
        const fullName = generation.name.includes(generationNum)
            ? generation.name
            : `${generationNum} ${generation.name}`;

        const validStartedAt =
            generation.startedAt && generation.startedAt >= START_YEAR_PRODUCTION
                ? generation.startedAt
                : undefined;
        const validEndedAt =
            generation.endedAt && generation.endedAt >= START_YEAR_PRODUCTION
                ? generation.endedAt
                : CURRENT_YEAR;

        const period = validStartedAt ? `${validStartedAt} - ${validEndedAt}` : "";
        const restyle =
            generation.restyleNumber && !generation.name.includes("Рестайлинг")
                ? `${generation.restyleNumber} Рестайлинг`
                : "";

        const fullLabel = [period, fullName, restyle].filter(Boolean).join(" ");

        return { value: generation.id, label: fullLabel };
    };

    const handleChangeLocation = () => {
        setIsLocationConfirmShown(false);
        setIsLocationFilterShown(true);
    };

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);

        if (!isExpanded && window.innerWidth <= BREAKPOINT_EXPANDED && targetExpandedRef.current) {
            targetExpandedRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
    };

    const topSelectsFontStyle = {
        fontWeight: 600,
        fontSize: 16,
        color: ColorInputAccent
    };

    useEffect(() => {
        const strVal = printNumber(previewQnt);
        if (strVal?.length > 0 && strVal !== "0") {
            setBtnLabel(
                `Показать ${strVal} ${createLabel(previewQnt || 0, [
                    "объявление",
                    "объявления",
                    "объявлений"
                ])}`
            );
        } else {
            setBtnLabel("Ничего не найдено");
        }
    }, [previewQnt]);

    useEffect(() => {
        const lastSavedTimestamp = localStorage.getItem("cityTimestamp");
        if (lastSavedTimestamp) {
            const lastSavedDateTime = DateTime.fromISO(lastSavedTimestamp);
            const now = DateTime.now();
            const daysSinceLastSave = now.diff(lastSavedDateTime, "days").days;

            if (daysSinceLastSave <= -7) {
                setIsLocationConfirmShown(true);
            } else {
                setIsLocationConfirmShown(false);
            }
        }
    }, []);

    return (
        <div
            className={`${classes.MainFilter} ${isExpanded ? classes.ExpandedMode : ""} ${
                isMainPage ? classes.MainPage : classes.InnerPage
            }`}
        >
            <div className={classes.Header}>
                <div className={classes.CitySelector}>
                    <LocationButton
                        onClick={() => {
                            setIsLocationFilterShown(!isLocationFilterShown);
                            setIsLocationConfirmShown(false);
                        }}
                        isConfirmShown={isLocationConfirmShown}
                    />
                </div>
                {isLocationConfirmShown && (
                    <div className={classes.LocationConfirmCont}>
                        <LocationConfirm
                            onClose={() => {
                                setIsLocationConfirmShown(false);
                            }}
                            onChange={handleChangeLocation}
                        />
                    </div>
                )}
                {isLocationFilterShown && (
                    <div className={classes.LocationFilterCont}>
                        <LocationFilter
                            onClose={() => {
                                setIsLocationFilterShown(false);
                            }}
                        />
                    </div>
                )}
                <div
                    className={`${classes.ModeSwitcher} ${isExpanded ? classes.Expanded : ""}`}
                    onClick={handleExpandClick}
                >
                    Расширенный поиск <ExpanderIcon />
                </div>
            </div>
            <div className={classes.Inputs}>
                <div className={classNames(classes.Row, classes.MainLine)}>
                    <div className={classes.Col}>
                        <SelectInput
                            width="100%"
                            height={50}
                            instanceId={"auto-brand"}
                            className={classes.Select}
                            placeholder={FILTER_LABELS.BRAND}
                            title={FILTER_LABELS.BRAND}
                            isClearable={true}
                            components={customSelectComponents}
                            isLoading={brandsOptionsIsFetching}
                            value={createSelectValue(
                                brandValue?.id,
                                brandValue?.name,
                                brandValue?.alias
                            )}
                            options={brandsOptionsFormatted}
                            formatOptionLabel={(brand) => <IconWithLabel option={brand} />}
                            onChange={(selectedOption) => {
                                const brand = selectedOption
                                    ? {
                                          id: selectedOption.value,
                                          name: selectedOption.label,
                                          alias: selectedOption?.alias
                                      }
                                    : undefined;
                                dispatch(setBrand(brand));
                            }}
                            fontStyles={topSelectsFontStyle}
                            borderColor="#B5BCCB"
                            borderRadius={10}
                        />
                    </div>
                    <div className={classes.Col}>
                        <SelectInput
                            width="100%"
                            height={50}
                            instanceId={"model-auto"}
                            className={classes.Select}
                            disabled={!brandValue}
                            isLoading={modelOptionsIsFetching}
                            placeholder={FILTER_LABELS.MODEL}
                            title={FILTER_LABELS.MODEL}
                            isClearable={true}
                            components={customSelectComponents}
                            value={createSelectValue(modelValue?.id, modelValue?.name)}
                            onChange={(selectedOption) => {
                                const model = selectedOption
                                    ? {
                                          id: selectedOption.value,
                                          name: selectedOption.label,
                                          alias: selectedOption.alias
                                      }
                                    : undefined;
                                dispatch(setModel(model));
                            }}
                            options={modelFormattedOptions}
                            fontStyles={topSelectsFontStyle}
                            borderColor="#B5BCCB"
                            borderRadius={10}
                        />
                    </div>
                    <div className={classes.Col}>
                        <SelectInput
                            width="100%"
                            height={50}
                            instanceId={"generation-auto"}
                            className={classes.Select}
                            disabled={!modelValue}
                            title={FILTER_LABELS.GENERATION}
                            placeholder={FILTER_LABELS.GENERATION}
                            isClearable={true}
                            components={customSelectComponents}
                            value={createGenerationSelectValue(generationValue)}
                            onChange={(selectedOption) => {
                                const generation = selectedOption
                                    ? {
                                          id: selectedOption.value,
                                          name: selectedOption.label,
                                          generation: selectedOption.generation,
                                          restyleNumber: selectedOption.restyleNumber
                                      }
                                    : undefined;
                                dispatch(setGeneration(generation));
                            }}
                            isLoading={isFetchingGenerations}
                            options={generationFormattedOptions}
                            fontStyles={topSelectsFontStyle}
                            borderColor="#B5BCCB"
                            borderRadius={10}
                        />
                    </div>
                </div>
                <div className={classNames(classes.Row, classes.MainLine)}>
                    <div className={classes.Col}>
                        <RangeInput
                            useSelectMode={true}
                            firstSelect={
                                <SelectInput
                                    width="100%"
                                    height={50}
                                    title={FILTER_LABELS.YEAR_FROM}
                                    placeholder={FILTER_LABELS.YEAR_FROM}
                                    isClearable={true}
                                    instanceId={"year-from-auto"}
                                    className={classes.Select}
                                    options={YEARS_PRODUCTION}
                                    borderColor="#B5BCCB"
                                    fontStyles={topSelectsFontStyle}
                                    value={createSelectValue(yearFromValue)}
                                    onChange={(value) => {
                                        dispatch(setYearFrom(value?.value));
                                    }}
                                />
                            }
                            secondSelect={
                                <SelectInput
                                    width="100%"
                                    height={50}
                                    isClearable={true}
                                    title={FILTER_LABELS.YEAR_TO}
                                    placeholder={FILTER_LABELS.YEAR_TO}
                                    instanceId={"year-to-auto"}
                                    className={classes.Select}
                                    options={YEARS_PRODUCTION}
                                    borderColor="#B5BCCB"
                                    fontStyles={topSelectsFontStyle}
                                    value={createSelectValue(yearToValue)}
                                    onChange={(value) => {
                                        dispatch(setYearTo(value?.value));
                                    }}
                                />
                            }
                        />
                    </div>
                    <div className={classes.Col}>
                        <RangeInput
                            fromLabel={FILTER_LABELS.MILEAGE_FROM}
                            toLabel={FILTER_LABELS.MILEAGE_TO}
                            onFromChanged={(value) => {
                                dispatch(setMileageFrom(value));
                            }}
                            onToChanged={(value) => {
                                dispatch(setMileageTo(value));
                            }}
                            fromVal={mileageFromValue}
                            toVal={mileageToValue}
                            maxLen={7}
                        />
                    </div>
                    <div className={classes.Col}>
                        <RangeInput
                            fromLabel={FILTER_LABELS.PRICE_FROM}
                            toLabel={FILTER_LABELS.PRICE_TO}
                            onFromChanged={(value) => {
                                dispatch(setPriceFrom(value));
                            }}
                            onToChanged={(value) => {
                                dispatch(setPriceTo(value));
                            }}
                            fromVal={priceFromValue}
                            toVal={priceToValue}
                            maxLen={9}
                        />
                    </div>
                </div>
                <div className={classNames(classes.Row, classes.MainLine)}>
                    <div className={classes.Col}>
                        <SelectInput
                            width="100%"
                            height={50}
                            instanceId={"source-auto"}
                            title={FILTER_LABELS.SOURCE}
                            className={classes.Select}
                            placeholder={FILTER_LABELS.PLATFORM}
                            isClearable={true}
                            isMulti={true}
                            value={PLATFORMS.filter((option) => sourceId?.includes(option.value))}
                            options={PLATFORMS}
                            onChange={(selectedOptions) => {
                                const selectedIds = selectedOptions
                                    ? selectedOptions.map((option: Option) => option.value)
                                    : [];
                                dispatch(setSource(selectedIds));
                            }}
                            borderColor="#B5BCCB"
                            fontStyles={topSelectsFontStyle}
                        />
                    </div>
                </div>
                {isExpanded && (
                    <>
                        <div className={classes.Divider} />
                        <div className={classes.ExpandedFilters}>
                            <div className={classes.ExpandedFilter}>
                                <FilterDoubleInput
                                    firstLabel={FILTER_LABELS.BODY}
                                    secondLabel={FILTER_LABELS.TRANSMISSION}
                                    firstInput={
                                        <SelectInput
                                            width="100%"
                                            instanceId={"kuzov-auto"}
                                            className={classes.Select}
                                            components={customSelectComponents}
                                            placeholder={FILTER_LABELS.ANY}
                                            title={FILTER_LABELS.BODY}
                                            isClearable={true}
                                            value={createSelectValue(bodyValue)}
                                            onChange={(value) => {
                                                dispatch(setBody(value?.value));
                                            }}
                                            isLoading={bodyOptionsIsFetching}
                                            options={
                                                bodyOptionsIsFetching
                                                    ? []
                                                    : (bodyOptions || []).map((brand: string) => ({
                                                          value: brand,
                                                          label: brand
                                                      }))
                                            }
                                            borderColor="#B5BCCB"
                                            fontStyles={selectInputFonts}
                                        />
                                    }
                                    secondInput={
                                        <SelectInput
                                            width="100%"
                                            fontStyles={selectInputFonts}
                                            instanceId={"transmission-auto"}
                                            className={classes.Select}
                                            components={customSelectComponents}
                                            placeholder={FILTER_LABELS.ANY_FEMALE}
                                            title={FILTER_LABELS.TRANSMISSION}
                                            isClearable={true}
                                            value={createSelectValue(transmissionValue)}
                                            onChange={(value) => {
                                                dispatch(setTransmission(value?.value));
                                            }}
                                            isLoading={transmissionOptionsIsFetch}
                                            options={
                                                transmissionOptionsIsFetch
                                                    ? []
                                                    : (transmissionOptions || []).map(
                                                          (model: string) => ({
                                                              value: model,
                                                              label: model
                                                          })
                                                      )
                                            }
                                            borderColor="#B5BCCB"
                                        />
                                    }
                                />
                            </div>
                            <div className={classes.ExpandedFilter}>
                                <FilterDoubleInput
                                    firstLabel={FILTER_LABELS.ENGINE_TYPE}
                                    secondLabel={FILTER_LABELS.DRIVE}
                                    firstInput={
                                        <SelectInput
                                            width="100%"
                                            title={FILTER_LABELS.ENGINE_TYPE}
                                            placeholder={FILTER_LABELS.ANY}
                                            instanceId={"engine-auto"}
                                            className={classes.Select}
                                            components={customSelectComponents}
                                            isClearable={true}
                                            fontStyles={selectInputFonts}
                                            value={createSelectValue(engineTypeValue)}
                                            onChange={(value) => {
                                                dispatch(setEngineType(value?.value));
                                            }}
                                            isLoading={engineTypesIsFetching}
                                            options={
                                                engineTypesIsFetching
                                                    ? []
                                                    : (engineTypesOptions || []).map(
                                                          (model: string) => ({
                                                              value: model,
                                                              label: model
                                                          })
                                                      )
                                            }
                                            borderColor="#B5BCCB"
                                        />
                                    }
                                    secondInput={
                                        <SelectInput
                                            width="100%"
                                            instanceId={"privod-auto"}
                                            title={FILTER_LABELS.DRIVE}
                                            placeholder={FILTER_LABELS.ANY}
                                            className={classes.Select}
                                            components={customSelectComponents}
                                            fontStyles={selectInputFonts}
                                            isClearable={true}
                                            value={createSelectValue(driveValue)}
                                            onChange={(value) => {
                                                dispatch(setDrive(value?.value));
                                            }}
                                            isLoading={driveTypesIsFetching}
                                            options={
                                                driveTypesIsFetching
                                                    ? []
                                                    : (driveTypesOptions || []).map(
                                                          (model: string) => ({
                                                              value: model,
                                                              label: model
                                                          })
                                                      )
                                            }
                                            borderColor="#B5BCCB"
                                        />
                                    }
                                />
                            </div>

                            <div className={classes.ExpandedFilter}>
                                <FilterDoubleInput
                                    firstLabel="Объем двигателя"
                                    secondLabel=""
                                    firstInput={
                                        <SelectInput
                                            title={FILTER_LABELS.ENGINE_VOLUME}
                                            placeholder={FILTER_LABELS.ENGINE_VOLUME_FROM}
                                            isClearable={true}
                                            width="100%"
                                            instanceId={"engine-volume-auto"}
                                            className={classes.Select}
                                            components={customSelectComponents}
                                            isLoading={displacementsIsFetching}
                                            options={
                                                displacementsIsFetching
                                                    ? []
                                                    : (displacementsOptions || []).map(
                                                          (disp: string) => ({
                                                              value: disp,
                                                              label: disp
                                                          })
                                                      )
                                            }
                                            borderColor="#B5BCCB"
                                            fontStyles={selectInputFonts}
                                            value={createSelectValue(engineVolumeFromValue)}
                                            onChange={(value) => {
                                                dispatch(setEngineVolumeFrom(value?.value));
                                            }}
                                        />
                                    }
                                    secondInput={
                                        <SelectInput
                                            width="100%"
                                            isClearable={true}
                                            title={FILTER_LABELS.ENGINE_VOLUME}
                                            placeholder={FILTER_LABELS.ENGINE_VOLUME_TO}
                                            instanceId={"engine-vol-to"}
                                            className={classes.Select}
                                            components={customSelectComponents}
                                            isLoading={displacementsIsFetching}
                                            options={
                                                displacementsIsFetching
                                                    ? []
                                                    : (displacementsOptions || []).map(
                                                          (disp: string) => ({
                                                              value: disp,
                                                              label: disp
                                                          })
                                                      )
                                            }
                                            borderColor="#B5BCCB"
                                            fontStyles={selectInputFonts}
                                            value={createSelectValue(engineVolumeToValue)}
                                            onChange={(value) => {
                                                dispatch(setEngineVolumeTo(value?.value));
                                            }}
                                        />
                                    }
                                />
                            </div>
                            <div className={classes.ExpandedFilter}>
                                <FilterDoubleInput
                                    firstLabel={FILTER_LABELS.HORSE_POWER}
                                    secondLabel=""
                                    firstInput={
                                        <>
                                            <TextInput
                                                placeholder={FILTER_LABELS.HORSE_POWER_FROM}
                                                value={horsePowerFromValue}
                                                onChange={(value) => {
                                                    dispatch(setHorsePowerFrom(value));
                                                }}
                                                type="number"
                                                maxLength={4}
                                                style={{
                                                    color: ColorInputAccent
                                                }}
                                            />
                                        </>
                                    }
                                    secondInput={
                                        <TextInput
                                            placeholder={FILTER_LABELS.HORSE_POWER_TO}
                                            value={horsePowerToValue}
                                            onChange={(value) => {
                                                dispatch(setHorsePowerTo(value));
                                            }}
                                            type="number"
                                            maxLength={4}
                                            style={{
                                                color: ColorInputAccent
                                            }}
                                        />
                                    }
                                />
                            </div>
                            <div className={classes.ExpandedFilter}>
                                <FilterLineInput
                                    label={FILTER_LABELS.WHEEL}
                                    component={
                                        <SelectInput
                                            width="100%"
                                            instanceId={"rul-auto"}
                                            title={FILTER_LABELS.WHEEL}
                                            placeholder={FILTER_LABELS.ANY}
                                            className={classes.Select}
                                            components={customSelectComponents}
                                            isClearable={true}
                                            value={createSelectValue(wheelValue)}
                                            onChange={(value) => {
                                                dispatch(setWheel(value?.value));
                                            }}
                                            isLoading={wheelsIsFetching}
                                            options={
                                                wheelsIsFetching
                                                    ? []
                                                    : (wheelsOptions || []).map(
                                                          (model: string) => ({
                                                              value: model,
                                                              label: model
                                                          })
                                                      )
                                            }
                                            borderColor="#B5BCCB"
                                        />
                                    }
                                />
                            </div>
                            <div className={classes.ExpandedFilter}>
                                <FilterDoubleInput
                                    firstLabel={FILTER_LABELS.OWNERS_QNT}
                                    secondLabel=""
                                    firstInput={
                                        <SelectInput
                                            width="100%"
                                            instanceId={"owners-qnt-auto"}
                                            title={FILTER_LABELS.OWNERS_QNT}
                                            placeholder={FILTER_LABELS.OWNERS_QNT_FROM}
                                            className={classes.Select}
                                            isClearable={true}
                                            value={createSelectValue(ownerQntFrom)}
                                            onChange={(value) => {
                                                dispatch(setOwnersQntFrom(value?.value));
                                            }}
                                            options={OWNERS_QNT}
                                            borderColor="#B5BCCB"
                                            fontStyles={selectInputFonts}
                                        />
                                    }
                                    secondInput={
                                        <SelectInput
                                            width="100%"
                                            instanceId={"owners-qnt-to-auto"}
                                            title={FILTER_LABELS.OWNERS_QNT}
                                            className={classes.Select}
                                            placeholder={FILTER_LABELS.OWNERS_QNT_TO}
                                            isClearable={true}
                                            value={createSelectValue(ownersQntTo)}
                                            onChange={(value) => {
                                                dispatch(setOwnersQntTo(value?.value));
                                            }}
                                            options={OWNERS_QNT}
                                            borderColor="#B5BCCB"
                                            fontStyles={selectInputFonts}
                                        />
                                    }
                                />
                            </div>
                            <div className={classes.ExpandedFilter}>
                                <FilterLineInput
                                    label={FILTER_LABELS.SELLER_TYPE}
                                    component={
                                        <SelectInput
                                            isClearable={true}
                                            width="100%"
                                            instanceId={"saler-type-auto"}
                                            title={FILTER_LABELS.SELLER_TYPE}
                                            placeholder={FILTER_LABELS.ANY}
                                            className={classes.Select}
                                            value={createSelectValue(sellerType)}
                                            onChange={(value) => {
                                                dispatch(setSeller(value?.value));
                                            }}
                                            isLoading={sellerTypesIsFetching}
                                            options={
                                                sellerTypesOptions
                                                    ? sellerTypesOptions.map((model: string) => {
                                                          return { value: model, label: model };
                                                      })
                                                    : []
                                            }
                                            borderColor="#B5BCCB"
                                            fontStyles={selectInputFonts}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className={classes.Divider}></div>
                    </>
                )}
            </div>
            <div ref={targetExpandedRef} className={classes.Footer}>
                <FilterButton
                    title={FILTER_LABELS.SEARCH_MAP}
                    type="primary-light"
                    icon={<LocationIcon />}
                    onClick={() => {
                        router.push(`${targetPath}?mapView=true`);
                    }}
                />
                <FilterButton
                    disabled={previewQnt === 0}
                    title={btnLabel}
                    loading={isPreviewQntFetching || updatePreviewQntIsLoading}
                    type="primary"
                    onClick={() => {
                        if (previewQnt === 0) {
                            return;
                        }
                        onFilterSubmit({});
                        if (disableMapListMode) {
                            disableMapListMode();
                        }
                    }}
                />
                <div className={classes.CancelContainer}>
                    <FilterButton
                        title={FILTER_LABELS.RESET_FILTERS}
                        type="cancel"
                        icon={<CancelIcon />}
                        onClick={() => {
                            resetFilters();
                        }}
                    />
                </div>

                <FilterButton
                    title={FILTER_LABELS.COMPARE}
                    type="light"
                    icon={
                        <div className={classes.IconWrapper}>
                            <CompareIcon className={classes.CompareIcon} />
                            {showBadgeCount && (
                                <span className={classes.CompareIcon__badge}>{compareCount}</span>
                            )}
                        </div>
                    }
                    onClick={() => {
                        router.push(RouterConfig.COMPARE);
                    }}
                />
            </div>
        </div>
    );
};

export default MainFilter;