import React from "react";
import classees from "./category-filter.module.scss";
import CategoryFilterCard from "./category-filter-card";
import { useDispatch } from "react-redux";
import { clearFilters, optimiseFilters, setPriceFrom, setPriceTo } from "@app/store/filtersSlice";
import { useRouter } from "next/router";
import { URI_CATALOG } from "@components/Features/Catalog/catalogUtils";

const CategoryFilter: React.FC = () => {
    const dispatch = useDispatch();
    const router = useRouter();

    const filter = (setFilters: () => void) => {
        return (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault();
            dispatch(clearFilters());
            setFilters();
            dispatch(optimiseFilters());
            router.push(URI_CATALOG);
        };
    };
    const under300 = filter(() => {
        dispatch(setPriceTo(300_000));
    });

    const under500 = filter(() => {
        dispatch(setPriceFrom(300_000));
        dispatch(setPriceTo(500_000));
    });

    const under1500 = filter(() => {
        dispatch(setPriceFrom(500_000));
        dispatch(setPriceTo(1_500_000));
    });

    const more3000 = filter(() => {
        dispatch(setPriceFrom(3_000_000));
    });

    return (
        <div className={classees.Wrap}>
            <label>Популярные подборки автомобилей</label>
            <div className={classees.Cont}>
                <CategoryFilterCard
                    callback={under300}
                    img="/img/car-by-price/f300.png"
                    price="до 300 000 ₽"
                />
                <CategoryFilterCard
                    callback={under500}
                    img="/img/car-by-price/f100000.png"
                    price="до 500 000 ₽"
                />{" "}
                <CategoryFilterCard
                    callback={under1500}
                    img="/img/car-by-price/f2000000.png"
                    price="до 1 500 000 ₽"
                />
                <CategoryFilterCard
                    callback={more3000}
                    img="/img/car-by-price/f3500000.png"
                    price="от 3 000 000 ₽"
                />
            </div>
        </div>
    );
};

export default CategoryFilter;