export const getPrecision = (zoom: number) => {
    const zoomMap: { [key: number]: number } = {
        5: 3,
        6: 4,
        7: 4,
        8: 4,
        9: 5,
        10: 5,
        11: 6,
        12: 6,
        13: 7,
        14: 7,
        15: 8,
        16: 8
    };
    return zoomMap[zoom] || 3;
};