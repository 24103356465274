import React from "react";
import classes from "./category-filter-card.module.scss";
import { CategoryFilterCardProps } from "./category-filter-card-props";

const CategoryFilterCard: React.FC<CategoryFilterCardProps> = ({ title, img, price, callback }) => (
    <div className={classes.Card} onClick={callback}>
        <div className={classes.CardImgPriceCont}>
            <img className={classes.Img} src={img} alt={title} />
            <div className={classes.PriceCont}>
                <div className={classes.Price}>{price}</div>
            </div>
        </div>
        <span>{title}</span>
    </div>
);

export default CategoryFilterCard;