import React from "react";
import { digitalKeyBoardPattern, numberWithSpaces } from "@src/utils/utils";
import { RangeInputProps } from "./types";
import classes from "./RangeInput.module.scss";

const RangeInput = ({
    fromVal,
    toVal,
    onFromChanged,
    onToChanged,
    fromLabel = "Год от",
    toLabel = "Год до",
    maxLen = 4,
    useSelectMode = false,
    firstSelect,
    secondSelect
}: RangeInputProps) => {
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        onChange?: (val: number | undefined) => void
    ) => {
        const val = event.target.value.replaceAll(" ", "");
        if (onChange) onChange(val ? parseInt(val.replace(/\D/g, ""), 10) : undefined);
    };

    return (
        <div className={classes.rangeInput}>
            {useSelectMode ? (
                <div className={classes.rangeInput__select}>{firstSelect}</div>
            ) : (
                <input
                    className={classes.rangeInput__from}
                    value={fromVal ? numberWithSpaces(fromVal) : ""}
                    onChange={(e) => onFromChanged && handleInputChange(e, onFromChanged)}
                    maxLength={maxLen}
                    placeholder={fromLabel}
                    type="text"
                    pattern={digitalKeyBoardPattern}
                    inputMode="numeric"
                />
            )}
            <div className={classes.rangeInput__divider}>&nbsp;</div>
            {useSelectMode ? (
                <div className={classes.rangeInput__select}>{secondSelect}</div>
            ) : (
                <input
                    className={classes.rangeInput__to}
                    value={toVal ? numberWithSpaces(toVal) : ""}
                    onChange={(e) => onToChanged && handleInputChange(e, onToChanged)}
                    maxLength={maxLen}
                    placeholder={toLabel}
                    type="text"
                    pattern={digitalKeyBoardPattern}
                    inputMode="numeric"
                />
            )}
        </div>
    );
};
export default RangeInput;