import { Sort } from "@components/Features/Catalog/catalogUtils";

export const sortValueToQueryParam = (sort: Sort | undefined): string | undefined => {
    switch (sort) {
        case Sort.Popular:
            return undefined;
        case Sort.Expensive:
            return "price@desc";
        case Sort.Cheap:
            return "price@asc";
        case Sort.YearNewer:
            return "year@desc";
        case Sort.YearOlder:
            return "year@asc";
        case Sort.MileageLess:
            return "mileage@asc";
        case Sort.MileageMore:
            return "mileage@desc";
        case Sort.ByName:
            return "title@asc";
        default:
            return undefined;
    }
};