import { useState, useEffect, useRef } from "react";

export const useDebounceValue = <T>(value: T, delay: number): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const timerId = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (timerId.current) {
            clearTimeout(timerId.current);
        }

        timerId.current = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            if (timerId.current) {
                clearTimeout(timerId.current);
            }
        };
    }, [value, delay]);

    return debouncedValue;
};