import React from "react";
import classNames from "classnames";
import { FilterButtonProps } from "./types";
import classes from "./FilterButton.module.scss";

const FilterButton = ({ icon, title, type, onClick, loading, disabled }: FilterButtonProps) => {
    const mapTypesButton = {
        primary: classes.Primary,
        cancel: classes.Cancel,
        "primary-light": classes.PrimaryLight,
        light: classes.Light
    };
    const iconOnly = !title && !!icon;
    const buttonClassNames = classNames(
        classes.FilterButton,
        iconOnly && classes.IconButton,
        mapTypesButton[type],
        {
            [classes.FilterButton_loading]: loading,
            [classes.FilterButton_disabled]: disabled
        }
    );

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (onClick) {
            onClick();
        }
    };

    return (
        <button className={buttonClassNames} type="button" onClick={handleClick}>
            {loading && <div className={classes.loader}></div>}
            {icon && icon}
            {title && <span>{title}</span>}
        </button>
    );
};
export default FilterButton;