import React from "react";
import { FilterLineInputProps } from "./types";
import classes from "./FilterLineInput.module.scss";

const FilterLineInput = (props: FilterLineInputProps) => {
    return (
        <div className={classes.FilterLineInput}>
            <label>{props.label}</label>
            <div className={classes.ComponentContainer}>{props.component}</div>
        </div>
    );
};
export default FilterLineInput;