import Head from "next/head";
import { SchemaJsonLdProps } from "@shared/ui/SchemaJsonLd/types";

export const SchemaJsonLd = ({ schemas }: SchemaJsonLdProps) => {
    return (
        <Head>
            <script
                data-testid="json-ld-script"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(schemas)
                }}
            />
        </Head>
    );
};