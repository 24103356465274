export const GEO_ERROR_BROWSER_UNSUPPORTED = "Геолокация не поддерживается вашим браузером.";
export const GEO_ERROR_YANDEX_FETCH = "Ошибка получения местоположения от Yandex:";
export const GEO_PERMISSION_WARNING_TEXT =
    "Для корректной работы функции определения местоположения, пожалуйста, разрешите доступ к геоданным в настройках вашего браузера.";
export const GEO_TOAST_ID = "geoPermissionWarning";
export const TOAST_TIME_CLOSE = 5000;
export const GEO_NAVIGATOR_OPTIONS = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0
};