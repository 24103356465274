import { DateTime } from "luxon";
import { TimeSort } from "@components/Features/Catalog/catalogUtils";

export const getCreatedTime = (interval?: TimeSort): string | null => {
    let now = DateTime.utc();

    switch (interval) {
        case TimeSort.Hour:
            now = now.minus({ hours: 1 });
            break;
        case TimeSort.ThreeHours:
            now = now.minus({ hours: 3 });
            break;
        case TimeSort.Day:
            now = now.minus({ days: 1 });
            break;
        case TimeSort.Week:
            now = now.minus({ weeks: 1 });
            break;
        case TimeSort.Month:
            now = now.minus({ months: 1 });
            break;
        case TimeSort.AllTime:
            return null;
    }
    return now.toISO();
};