import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const SearchIcon: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9638 13.6461C11.5882 14.862 9.7803 15.6 7.8 15.6C3.49218 15.6 0 12.1078 0 7.8C0 3.49218 3.49218 0 7.8 0C12.1078 0 15.6 3.49218 15.6 7.8C15.6 9.44405 15.0914 10.9693 14.2229 12.227C14.2636 12.2564 14.3026 12.2895 14.3393 12.3262L15.8407 13.8277C16.213 14.2 16.213 14.8036 15.8407 15.1759C15.4684 15.5482 14.8648 15.5482 14.4925 15.1759L12.991 13.6744C12.9817 13.6651 12.9727 13.6557 12.9638 13.6461ZM13.6933 7.8C13.6933 11.0548 11.0548 13.6933 7.8 13.6933C4.5452 13.6933 1.90667 11.0548 1.90667 7.8C1.90667 4.5452 4.5452 1.90667 7.8 1.90667C11.0548 1.90667 13.6933 4.5452 13.6933 7.8Z"
            fill="#7B869B"
        />
    </svg>
);